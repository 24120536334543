import Vue from 'vue'
import Router from 'vue-router'

const routerOptions = [
  { path: "/", component: "Landing", meta: {title: 'Home'} },
  { path: "/register", component: "Register", meta: {title: 'Mailing List'} },
  { path: "/alumni", component: "Alumni", meta: {title: 'Alumni'} },
  { path: "*", component: "NotFound", meta: {title: '404'} }
];

const routes = routerOptions.map(route => {
  return {
    ...route,
    component: () => import(`../components/${route.component}.vue`)
  };
});

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes
});

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = 'Class of 2010 | ' + to.meta.title;
    });
});

export default router;
