<template>
  <div id="app">
    <v-app id="inspire" style="background-color: #28282A">

      <v-app-bar light
        color=#FFDF1B style="max-height: 64px"
      >
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title
          @click="$router.push('/')"
        >
          <img src="@/assets/logo_transparent.png"/>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch
          color=#28282A
          v-model="$vuetify.theme.dark"
        ></v-switch>

      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        style="text-align: left"
      >
        <v-list
          nav
        >
          <v-list-item-group
            v-model="group"
          >
          <v-list-item v-for="link in links"
            v-bind:key="link.id"
            :to="link.page"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            :href="'https://www.eventbrite.com/e/360276686267'"
          >
            <v-list-item-icon>
              <v-icon>mdi-ticket-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>2022 Reunion</v-list-item-title>
          </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <router-view/>

    </v-app>
  </div>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    drawer: false,
    group: null,
    links: [
      {
        id: 0,
        text: 'Home',
        icon: 'mdi-home',
        page:'/'
      },
      {
        id: 1,
        text: 'Mailing List',
        icon: 'mdi-at',
        page:'/register'
      },
      {
        id: 2,
        text: 'Alumni List',
        icon: 'mdi-clipboard-list-outline',
        page:'/alumni'
      }
    ]
  }),
  mounted: function () {
    this.$vuetify.theme.dark = false
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.spacing {
  margin-right: 10px;
}
a, a:hover, a:active, a:visited, a:focus {
    text-decoration: none;
    color: black;
    font-size: 2rem
}
img {
  max-height: 2rem;
}
</style>
