import Vue from 'vue'
import VueMatomo from 'vue-matomo'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.config.productionTip = false

Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://matomo.rpenziol.com',
  siteId: 1,
  router: router,
})

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
